import { Listings } from "../market-insights-report/market-insights.model";

export class MapListings {
  public center?: center;
  public limit?: number;
  public minCloseDate?: string;
  public minListDate?: string;
  public radiusInMiles?: number;
  public status?: any;
}

export class center {
  lat: any;
  lng: any;
}

export class Location {
  latitude: any;
  longitude: any;
}

export class Highlights {
  public views?: number;
  public favorites?: number;
  public from?: string;
  public to?: string;
  public listing?: Listings;
}

export class OpenHouses {
  public count?: number;
  public date?: string;
}

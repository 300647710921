import { Component, OnInit, ViewChild } from '@angular/core';
import { SellerReportService } from './seller-report.service'
import { Highlights, Location } from './seller-report.model'
import { NgxSpinnerService } from 'ngx-spinner';
import { GoogleMap } from '@angular/google-maps';
import { Address, Listings } from '../market-insights-report/market-insights.model';
import { CommonService } from '../common.service';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-seller-report',
  templateUrl: './seller-report.component.html',
  styleUrls: ['./seller-report.component.css']
})
export class SellerReportComponent implements OnInit {

  publicUrl!: string;
  mapListings!: Listings[];
  openHouses: any = [];
  brokerDetails: any;
  listingId!: number;
  listingData?: Listings = new Listings;
  location?: Location = new Location;
  chartData: any;
  favoriteViews: any;
  listingViews: any;
  date: any;
  chart: any = [];
  labelMonth: any;
  address?: Address = new Address;
  highlights: Highlights = new Highlights;
  trends: any;
  viewAllUrl!: string;
  isHighlights!: boolean;
  isOpenhouses!: boolean;
  listingDate!: string;
  noChartData: boolean = false;
  noMapData: boolean = false;
   noOpenhouses: boolean = false;
  isError: boolean = false;
  doneChartData: boolean = false;
  doneHighlights: boolean = false;
  doneOpenhouses: boolean = false;
  doneMapData: boolean = false;
  p: number = 1;
  publicSite: string = location.href.replace(location.pathname, '');

  constructor(private sellerReportService: SellerReportService, public datepipe: DatePipe, private spinner: NgxSpinnerService, private commonService: CommonService, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.show();
    this.listingId = this.router.snapshot.params['id'];
    this.getPublicUrl();
  }

  @ViewChild(GoogleMap, { static: false })
  map!: GoogleMap;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap', 
    scrollwheel: false,
    clickableIcons: false,
    gestureHandling: 'auto',
    keyboardShortcuts: false,
    mapTypeControl: false,
    disableDefaultUI: true,
  };
  circleOptions: google.maps.CircleOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#F6F6F6",
    fillOpacity: 0.5
  };
  markerOptions: google.maps.MarkerOptions = { draggable: false, icon: '/Images/map-sold-ng.png' };
  zoom = 13;
  circleCenter!: google.maps.LatLngLiteral;
  radius = 1609;
  markers = [] as any;

  getPublicUrl() {
    this.sellerReportService.getPublicSiteLink().subscribe((data) => {
      this.publicUrl = data.url;
      if (this.publicUrl) {
        this.getBrokerDetails();
        this.getHighlights();
        this.getTrends();
        this.getListing();
        this.getOpenhouses();
      }
    })
  }

  getBrokerDetails() {
    this.commonService.getBrokerDetails(this.publicUrl).subscribe((data) => {
      this.brokerDetails = data;
      this.commonService.setBrokerDetailsResponse(this.brokerDetails);
    });
  }

  checkDoneAll() {
    if (!this.doneChartData) return;
    if (!this.doneHighlights) return;
    if (!this.doneMapData) return;
    this.spinner.hide();
  } 

  getHighlights() {
    this.sellerReportService.getHighlights(this.publicUrl, this.listingId).subscribe((response) => {
      this.isHighlights = true;
      this.highlights = response;
      if (!this.highlights) {
        this.isHighlights = false;
      }
      this.doneHighlights = true;
      this.checkDoneAll();
    },
      (error) => {
        console.error(error);
        this.isError = true;
        this.isHighlights = false;
        this.doneHighlights = true;
        this.doneChartData = true;
        this.checkDoneAll();

      });
  }

  getListing() {
    this.sellerReportService.getListing(this.publicUrl, this.listingId).subscribe((response) => {
      this.listingData = response
      this.location = this.listingData?.location;
      if (this.listingData) {
        this.getMapRadius();
      }
      this.address = this.listingData?.address;
    });
  }

 getOpenhouses() {
    this.sellerReportService.getOpenhouses(this.publicUrl, this.listingId).subscribe((response) => {
      this.isOpenhouses = true;
      this.openHouses = response;
      if (!this.openHouses) {
        this.isOpenhouses = false;
        this.noOpenhouses = true;
      }
      this.doneOpenhouses = true;
      this.checkDoneAll();
    },
      (error) => {
        console.error(error);
        this.isError = true;
        this.isHighlights = true;
        this.isOpenhouses = false;
        this.doneHighlights = true;
        this.doneOpenhouses = true;
        this.doneChartData = true;
        this.checkDoneAll();

      });
  }
  getTrends() {
    this.sellerReportService.getTrends(this.publicUrl, this.listingId).subscribe((response) => {
      this.trends = response;
      this.doneChartData = true;
      this.checkDoneAll();
      this.listingViews = this.trends.map((m: any) => m.listingViews);
      this.favoriteViews = this.trends.map((m: any) => m.favoriteViews);
      debugger
      if (this.trends.length === 0) {
        this.noChartData = true;
      }
      this.date = this.trends.map((m: any) => this.datepipe.transform(m.date, 'dd MMM'));
      this.chart = new Chart('canvas', {
        type: 'line',
        data: {
          labels: this.date,
          datasets: [
            {
              label: 'Listing Views',
              data: this.listingViews,
              borderColor: '#4d008e',
              backgroundColor: '#4d008e',
              pointBackgroundColor: '#4d008e',
              pointBorderColor: '#4d008e',
              pointHoverBackgroundColor: '#4d008e',
              pointHoverRadius: 8,
              tension: 0.4
            },
            {
              label: 'Saved as Favorite',
              data: this.favoriteViews,
              borderColor: '#246b58',
              backgroundColor: '#246b58',
              pointBackgroundColor: '#246b58',
              pointHoverBackgroundColor: '#246b58',
              pointHoverRadius: 8,
              tension: 0.4
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            yAxis: {
              ticks: {
                maxTicksLimit: 20
              }
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
            }
          }
        },
      })
    },
      (error) => {
        console.error(error);
        this.noChartData = true;
        this.doneChartData = true;
        this.checkDoneAll();
      });

  }

  getMapRadius() {
    this.sellerReportService.getMapRadius(this.publicUrl, this.location).subscribe((response) => {
      this.mapListings = response;
      this.circleCenter = { lat: this.location?.latitude, lng: this.location?.longitude };
      if (this.mapListings) {
        this.addMarker();
      }
      if (this.mapListings.length === 0) {
        this.noMapData = true;
      }
      this.doneMapData = true;
      this.checkDoneAll();
    },
      (error) => {
        console.error(error);
        this.noMapData = true;
        this.doneMapData = true;
        this.checkDoneAll();
      });

  }

  addMarker() {
    this.mapListings.forEach((listing) => {
      this.markers.push({
        position: {
          lat: listing.location?.latitude,
          lng: listing.location?.longitude,
        }
      });
    });
  }
}


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div class="row" style="padding: 50px 0 10px;">
  <div class="col-12 col-md-6">
    <h1>Seller Report</h1>
    <p style="padding: 20px 0 0; font-size:18px;">
      This report showcases online listing view metrics from John L. Scott websites, the John L. Scott Home Search mobile app (for both Android and iOS devices), and Zillow.com. It also highlights listings that users have saved as favorites on John L. Scott® platforms. Overall, it provides a picture of how potential buyers are viewing and interacting with properties online.
    </p>
   <!-- <p style="font-size:18px">* Third party sources include[insert known sources]</p>-->
  </div>
  <div class="col-12 col-md-5 offset-md-1" *ngIf="isHighlights">
    <app-broker-signature [brokerDetails]="brokerDetails"></app-broker-signature>
  </div>
</div>
<div class="row" style="margin:35px 0;" *ngIf="isError">
  <h4>Sorry, no data available for requested report.</h4>
</div>
<div class="row">
  <div class="col-12 col-md-6">
    <img style="height: 400px;" src="{{listingData?.mainPhoto?.target}}" class="card-img-top" alt="...">
    <p style="padding: 0 15px;">
      <span>MLS: {{listingData?.mlsListingId}} </span><span>
        | JLS:
        {{listingData?.id}}
      </span><span style="float:right">
        Listed: {{listingData?.listDate | date:"MM/dd/yyyy"}}
      </span>
    </p>
  </div>
  <div class="col-12 col-md-5" style="text-align: center; ">
    <div style="padding: 16% 0 20%">
      <h3 style="text-align:center; font-weight:bold;">Online Listing Activity</h3>
      <p style="text-align:center; font-size:20px">
        {{highlights.from | date: "MM/dd/yyyy"}} - {{highlights.to | date: "MM/dd/yyyy"}}
      </p>
      <div class="col-4" style="display: inline-block; margin-top: 10px">
        <h1 style="font-weight: bold; font-size: 3rem;">{{highlights.views}}</h1>
        <h4 style="word-spacing: 100vw; margin-bottom:15px;">Listing Views</h4>
      </div>
      <div class="col-4" style="display: inline-block; margin-top:10px;">
        <h1 style="font-weight: bold; font-size: 3rem;">{{highlights.favorites}}</h1>
        <h4 style="word-spacing: 100vw; margin-bottom:15px;">Saved Favorites</h4>
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top:10px;">
  <div class="col-12 col-md-2">
    <p style="margin:0;color: white;">
      <span style="background-color:green;">{{listingData?.statusName}} </span>
    </p>
    <p style="font-weight:bold;">{{listingData?.price | currency:'USD' : 'symbol' : '2.0'}}</p>
  </div>
  <div class="col-12 col-md-1">
    <p style="margin:0">Beds</p>
    <p style="font-weight:bold;">{{listingData?.beds}}</p>
  </div>
  <div class="col-12 col-md-1">
    <p style="margin:0">Baths</p>
    <p style="font-weight:bold;">{{listingData?.baths}}</p>
  </div>
  <div class="col-12 col-md-1">
    <p style="margin:0">SqFt</p>
    <p style="font-weight:bold;">{{listingData?.sqft | number}}</p>
  </div>
  <div class="col-12 col-md-1">
    <p style="margin:0">Lot (Ac.)</p>
    <p style="font-weight:bold;">{{listingData?.lotSize}}</p>
  </div>
  <div class="col-12 col-md-3" style="font-weight:bold;">
    <p style="margin:0">{{address?.street}} {{address?.street2}}</p>
    <p>{{address?.city}},{{address?.state}} {{address?.zip}}</p>
  </div>
  <div class="col-12 col-md-2">
    <p style="font-size: 18px"><a target="_blank" href="/listing/{{listingData?.id}}">View Listing</a></p>
  </div>
</div>

<div class="row" style="margin-top:35px;">
  <div class="col-12">
    <h3 style="font-weight:bold">
      Online Activity Trends
    </h3>
    <hr style=" border-top: 3px solid rgba(0,0,0,.2); " />
    <canvas id="canvas" style="height:450px !important; max-height:450px; margin-top:20px;" *ngIf="!noChartData">
      {{chart}}
    </canvas>
    <p *ngIf="noChartData">No Trends data available</p>
  </div>
</div>
<br />

<!--<div class="row">
  <div class="col-12" *ngIf="!noOpenhouses">
    <h4><span style="font-weight:bold">Open House Attendees</span></h4>
    <table class="table">
      <thead>
        <tr style="text-align:right;">
          <th style="text-align: start">Date</th>
          <th>Attendees</th>          
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let house of openHouses" style="text-align:right;">
          <td style="text-align: start">{{house.date | date: "MM/dd/yyyy"}}</td>
          <td>{{house.count}}</td>          
        </tr>
      </tbody>
    </table>    
  </div>
</div>-->
<br />
<div class="row" >
  <div class="col-12">
    <h4><span style="font-weight:bold">Recent Nearby Sales</span> (<180 days)</h4>
    <table class="table">
      <thead>
        <tr style="text-align:right;">
          <th style="text-align: start">Closed Date</th>
          <th>Beds</th>
          <th>Baths</th>
          <th>SqFt</th>
          <th>Lot (Ac.)</th>
          <th style="text-align: start">Street Address</th>
          <th style="text-align: start">City</th>
          <th>Zip</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sold of mapListings | paginate: { itemsPerPage: 6, currentPage: p }" style="text-align:right;">
          <td style="text-align: start">{{sold.soldData?.soldDate | date: "MM/dd/yyyy"}}</td>
          <td>{{sold.beds}}</td>
          <td>{{sold.baths}}</td>
          <td>{{sold.sqft | number}}</td>
          <td>{{sold.acreage}}</td>
          <td style="text-align:start">{{sold.address?.street}}</td>
          <td style="text-align:start">{{sold.address?.city}}</td>
          <td>{{sold.address?.zip}}</td>
          <td><a href="{{publicSite}}/listing/{{sold?.id}}" target="_blank">View</a></td>
        </tr>
      </tbody>
    </table>
    <p style="font-style: italic; padding-left:15px; margin-bottom:0;">Sold properties within a 1/2 mile radius and within +/-20% of the list price of your property.</p>
    <pagination-controls class="pull-right" (pageChange)="p = $event"></pagination-controls>
  </div>
</div>

<div class="col-12" *ngIf="!noMapData" style="margin-top:20px;">
  <google-map height="500px"
              width="100%"
              [options]="options"
              [center]="circleCenter"
              [zoom]="zoom">
    <map-circle [center]="circleCenter"
                [radius]="radius"
                [options]="circleOptions"></map-circle>
    <map-marker *ngFor="let marker of markers"
                [position]="marker.position"
                [options]="markerOptions">
    </map-marker>
  </google-map>
</div>
